<template>
  <b-row>
    <b-col cols="12">
      <b-card-text class="mb-0">
        Παρακάτω θα βρείτε τα τηλέφωνα που πρέπει να κάνετε κατά τη διάρκεια της δημοπρασίας για τις τηλεφωνικές προφορές. Τα lots φωτίζονται σύμφωνα την ροή της δημοπρασίας.
      </b-card-text>
      <!-- types -->
      <app-collapse
        accordion
        :type="collapseType"
      >
        <app-collapse-item
          v-for="offer in auction_offers"
          :key="offer.offer_id"
          :title="offer.item"
          :class="{ 'text-primary': (parseInt(offer.offer_item_id) === parseInt(current_lot)) }"
        >

          <b-card
              :img-src="'https://erp.vergosauctions.com' + offer.path"
              img-alt="Card image cap"
              img-left
              :title="offer.visitor_number + ' - ' + offer.display_name"
              img-width="200px"
          >
            <b-card-text>
              Εύρως τιμής: <b>{{ offer.item_range_price }}</b> <span v-if="parseFloat(offer.offer_max_price) > 0">- Μέγιστο χτύπημα: <b>{{ offer.offer_max_price }}</b></span> <hr>
              Στοιχεία επικοινωνίας: <b v-if="offer.client_phone1 != ''"><b-link :href="'tel:'+offer.client_phone1">{{offer.client_phone1}}</b-link> |</b> <b v-if="offer.client_phone2 != ''"><b-link :href="'tel:'+offer.client_phone2">{{offer.client_phone2}}</b-link> |</b> <b v-if="offer.client_mobile != ''"><b-link :href="'tel:'+offer.client_mobile">{{offer.client_mobile}}</b-link></b>
            </b-card-text>
            <b-button @click="previewItem(offer.item_id, offer.offer_auction_id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
            >
              Προβολή αντικειμένου
            </b-button>
          </b-card>
        </app-collapse-item>
      </app-collapse>
    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody, BButton, BFormRadioGroup, BFormRadio, BFormGroup, BLink} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
// eslint-disable-next-line import/extensions
import AppCollapse from '@core/components/app-collapse/AppCollapse'
// eslint-disable-next-line import/extensions
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BButton,
    BCardCode,
    AppCollapse,
    AppCollapseItem,
    BFormRadioGroup,
    BFormRadio,
    BCardText,
    BFormGroup,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      collapseType: 'margin',
      productCheckInterval: null,
    }
  },
  computed: {
    auction_offers() {
      return JSON.parse(localStorage.getItem('auction_offers'))
    },
    current_lot() {
      return this.$store.getters['data/getCurrentLot']
    },
  },
  methods: {
    previewItem(itemId, auctionId) {
      window.open(`https://vergosauctions.com/auctions/detail/category/4/auction/${auctionId}/item/${itemId}`, '_blank')
    },
  },
  mounted() {
    this.productCheckInterval = setInterval(() => {
      this.$store.dispatch('data/fetchCurrentLot')
    }, 3000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.productCheckInterval)
    next()
  },
}
</script>
